import React from "react";
import img from "./img/escalable-img.jpg";
import { Link } from "react-router-dom";
import "./Home.css";
const Home = () => {
  return (
    <div className="home-container">
      <div className="title-container">
        <h1>T’agradaria provar els productes mesoestetic®?</h1>
        <p>¿Te gustaría probar los productos mesoestetic®?</p>
      </div>
      <div className="text-container">
        <p>
          Contesta aquest breu qüestionari i emporta't una rutina de mostres
          personalitzades. /
        </p>
        <p>
          Contesta este breve cuestionario y llévate una rutina de muestras
          personalizadas.
        </p>
      </div>
      <div className="image-container">
        <img src={img} alt="Mesoestetic products" />
      </div>
        <Link to="/questionnaire">
      <div className="primary-btn">
          <span>Començar!</span> /¡Empezar!
      </div>
        </Link>
    </div>
  );
};
export default Home;
