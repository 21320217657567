import React, { createContext, useContext } from 'react';

const azafataContext = createContext();
const AzafataProvider = (props) => {
  const urlString = window.location.href;
  const url = new URL(urlString);
  const id = url?.searchParams.get('id');
  return <azafataContext.Provider value={{id}} {...props} />;
};
const useAzafata = () => {
  const context = useContext(azafataContext);
  if (context === undefined) {
    throw new Error("useAzafata can't be used without an AzafataProvider");
  }
  return context;
};
export { useAzafata, AzafataProvider };
