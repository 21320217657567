import React, { useEffect, useState } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import Home from "./pages/Home";
import Questionnaire from "./pages/Questionnaire";
import Success from "./pages/Success";
import logo from "./logo.svg";
import "./App.css";
import Questionaire_json from "./questionnaire.json";
import Focus_Combinations from "./focus-combinations.json";
import NOFocus_Combinations from "./nofocus-combinations.json";
import Products_json from './products.json';
const App = () => {
  const [questionnaire, setQuestionnaire] = useState(Questionaire_json);
  const [customerName,setCustomerName] = useState("");
  const [products, setProducts] = useState(Products_json);
  const [year, setYear] = useState();
  const history = useHistory()
  const calculateResult = (questionnaire) => {
    let response;
    let today = new Date();
    const dob = year.split("/");
    let difference = today - new Date(dob[2], dob[1], dob[0]);
    let totalDays = difference / (1000 * 60 * 60 * 24);
    let totalYears = totalDays / 365;
    let age;
    if (totalYears < 30) {
      age = "<30";
    } else if (totalYears > 30 && totalYears < 40) {
      age = "31-40";
    } else if (totalYears > 40 && totalYears <= 50) {
      age = "41-50";
    } else if (totalYears > 50) {
      age = ">51";
    }
    questionnaire.questions[0].options.map((option) => {
      if (option.selected) return response = option.value;
    });
    const url = window.location.href;
    if(url.includes("vip")) {
      const productsResult = [];
      Focus_Combinations[response][age].map(combination => {
        const result = products.find(prod => prod.id === combination);
        if(result) productsResult.push(result)
      })
      setProducts(productsResult)
    }else{
      const productsResult = [];
      NOFocus_Combinations[response][age].map(combination => {
        const result = products.find(prod => prod.id === combination);
        if(result) productsResult.push(result)
      })
      setProducts(productsResult)
    }
    history.push("/success");
  };
  useEffect(() => {
    if (year) calculateResult(questionnaire);
    // eslint-disable-next-line
  }, [year]);
  return (
    <div>
      <nav className="header">
        <a href="https://www.mesoestetic.es/" title="Tienda Mesoestetic">
          <img src={logo} alt="Logo Mesoestetic" />
        </a>
      </nav>
      <main className="content">
        <div className="content-container">
          <Switch>
            <Route path="/success">
              <Success products={products} customerName={customerName} />
            </Route>
            <Route path="/questionnaire">
              <Questionnaire
                questionnaire={questionnaire}
                setQuestionnaire={setQuestionnaire}
                setYear={setYear}
                calculateResult={calculateResult}
                setCustomerName={setCustomerName}
              />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
      </main>
    </div>
  );
};

export default App;
