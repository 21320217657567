import React from "react";
import "./success.css";
import brightfoam from "./img/brightfoam.jpg";
import hadensi from "./img/hadensi.jpg";
import colla360 from "./img/colla360.jpg";
import stemgrowth from "./img/colla360.jpg";
import radiandna from "./img/colla360.jpg";
import hydrafusion from "./img/hydrafusion.jpg";
import hydralight from "./img/hydralight.jpg";
import { useHistory } from "react-router";
const getToday = () => {
  const date_ob = new Date();
  let date = ("0" + date_ob.getDate()).slice(-2);
  let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
  let year = date_ob.getFullYear();
  let hours = date_ob.getHours();
  let minutes = date_ob.getMinutes();
  minutes = (minutes < 10) ? `0${minutes}` : minutes
  return date + "/" + month + "/" + year + " " + hours + ":" + minutes;
};
const objIMG = {
  brightfoam,
  hadensi,
  colla360,
  stemgrowth,
  radiandna,
  hydrafusion,
  hydralight
};
const Success = ({ products, customerName }) => {
  const history = useHistory();
  if(!customerName) history.push("/");
  return (
    <div className="success-page">
      <div className="bloque">
        <div className="date">{getToday()+"h"}</div>
        <h1 className="title">
          Hola {customerName}, recull les mostres gratuïtes al nostre stand!{" "}
        </h1>
        <p className="subtitle">¡Recoge las muestras gratuitas en el stand!</p>
        <p className="catalan">
          Aquesta és la teva rutina mesoestetic® personalitzada.{" "}
          <strong>Mostra en el nostre stand</strong> aquesta pàgina o{" "}
          <strong>el pdf que et pots descarregar</strong> a continuació{" "}
          <strong>per recollir el teu obsequi.</strong>
        </p>
        <p className="espanol">
          <em>
            Esta es tu rutina mesoestetic® personalizada.{" "}
            <strong>Muestra en nuestro stand</strong> esta página o{" "}
            <strong>el pdf que te puedes descargar</strong> a continuación{" "}
            <strong>para recoger tu obsequio.</strong>
          </em>{" "}
        </p>
        <div className="product-steps">
          {products.map((prod, index) => (
            <a href={"#" + prod.id} className="img-content" key={index}>
              <img src={objIMG[prod.id]} alt="" />
              <p>{`paso ${index + 1}`}</p>
            </a>
          ))}
        </div>
        <div className="divisor"></div>
      </div>

      {products.map((prod, index) => (
        <div className="steps" id={prod.id} key={index} onClick={() => window.open(prod.url)}>
          <div className="title">
            <div className="img-content">
              <img src={objIMG[prod.id]} alt="" />
            </div>
            <h2 className="name-product">{prod.name}</h2>
          </div>
          <div className="info">
            <span className="catalan">{prod.cat_description}</span>
            <em>
              {" "}
              / <span>{prod.es_description}</span>
            </em>
          </div>
        </div>
      ))}
      <div className="button-container">
        <div className="button-download" onClick={() => window.print()}>
          <span className="catalan">Descarregar PDF </span>
          <span className="espanol"> / Descargar PDF</span>
        </div>
      </div>
    </div>
  );
};
export default Success;
