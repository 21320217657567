import React, { useState } from "react";
import { Link } from "react-router-dom";
import Form from "../../components/Form";
import "./questionnaire.css";
const letters = ["A", "B", "C", "D", "E", "F"];
const Questionnaire = ({
  questionnaire,
  setQuestionnaire,
  setYear,
  calculateResult,
  setCustomerName,
}) => {
  const [counter, setCounter] = useState(0);
  const [answerError, setAnswerError] = useState(false);
  const selectAnswer = (currentCounter, index) => {
    const newQuest = { ...questionnaire };
    newQuest.questions[currentCounter].options.map(
      (option) => (option.selected = false)
    );
    newQuest.questions[currentCounter].options[index].selected = true;
    setQuestionnaire({ ...newQuest });
    if (newQuest.questions[currentCounter].options[index].special) {
      setAnswerError(false);
      return setCounter(() => counter + 2);
    }
    setAnswerError(false);
    return setCounter(() => counter + 1);
  };
  const nextQuestion = (currentCounter) => {
    let correct = false;
    let moveForward = 1;
    questionnaire.questions[currentCounter].options.map((option) => {
      if (+currentCounter === 3 && option.special && option.selected) {
        correct = true;
        moveForward = 2;
      } else if (option.selected) {
        correct = true;
      }
    });
    if (!correct) return setAnswerError(true);
    setAnswerError(false);
    return setCounter(currentCounter + moveForward);
  };
  return (
    <div className="questionnaire-container">
      {counter < 5 && questionnaire.questions && (
        <div>
          <div className="question">
            <p className="catalan">
              {questionnaire.questions[counter].text_catalan}
            </p>
            <p className="espanol">
              {questionnaire.questions[counter].text_espanol}
            </p>
          </div>
          <div className="progress-bar">
            <p className="percent">
              {(
                (100 / (questionnaire.questions.length + 1)) *
                (counter + 1)
              ).toPrecision(2)}{" "}
              %
            </p>
            <div className="empty-bar">
              <div
                className="filled-bar"
                style={{
                  width: `${
                    (100 / (questionnaire.questions.length + 1)) *
                    (counter + 1).toPrecision(2)
                  }%`,
                }}
              ></div>
            </div>
          </div>
          {answerError && (
            <div className="error-box">
              <p>Seleccioneu una resposta</p>
              <p>Por favor, seleccione una respuesta.</p>
            </div>
          )}
          <div className="answers">
            {questionnaire.questions[counter].options.map((option, index) => (
              <div
                key={index}
                onClick={() => selectAnswer(counter, index)}
                className={option.selected ? "selected" : ""}
              >
                <span className="letter">{letters[index]}</span>{" "}
                {option.text_catalan} / <span>{option.text_espanol}</span>
              </div>
            ))}
          </div>
        </div>
      )}
      {counter === 5 && (
        <div>
          <div>
            <p>
              Abans de finalitzar el test i obtenir el teu resultat,{" "}
              <strong>emplena les següents dades.</strong> /
            </p>
            <p>
              Antes de finalizar el test y obtener tu resultado, rellena los
              siguientes datos.
            </p>
          </div>
          <div className="progress-bar">
            <p className="percent">100 %</p>
            <div className="empty-bar">
              <div
                className="filled-bar"
                style={{
                  width: "100%",
                }}
              ></div>
            </div>
          </div>
          <Form
            questionnaire={questionnaire}
            counter={counter}
            setCounter={setCounter}
            setYear={setYear}
            calculateResult={calculateResult}
            setCustomerName={setCustomerName}
          />
        </div>
      )}
      {counter < 5 && (
        <div className="actions">
          {counter > 0 && (
            <span className="secondary" onClick={() => setCounter(counter - 1)}>
              {" "}
              Enrere <span>/ Atrás</span>{" "}
            </span>
          )}
          {counter === 0 && (
            <Link to="/">
              {" "}
              Enrere <span> / Atrás</span>{" "}
            </Link>
          )}
          <span className="primary" onClick={() => nextQuestion(counter)}>
            <span>Següent </span>/ Siguiente
          </span>
        </div>
      )}
    </div>
  );
};
export default Questionnaire;
