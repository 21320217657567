import React from 'react';
import { DefaultForm } from './style';
import { useMailchimp, Status } from './Mailchimp.hook';
import PDF from './Términos_y_condiciones(ES).pdf';
import { useAzafata } from '../../context/azafata.context';
const getTextValue = (questionnaire, questionID) => {
  let result;
  questionnaire.questions[questionID - 1].options.map((option) => {
    if (option.selected) return (result = option.text_espanol);
  });
  return result;
};
const getFormattedDOB = (day, month, year) => {
  let formatted_date;
  formatted_date = `${day}/${month}/${year}`;
  return formatted_date;
};
const Form = ({
  questionnaire,
  counter,
  setCounter,
  setYear,
  setCustomerName,
}) => {
  const { subscribe, status, error } = useMailchimp(
    'https://mesoestetic.us7.list-manage.com/subscribe/post?u=4b1641f9e6ae6f9ff5bddf08b&id=973b6861f5'
  );
  const { id: azafataId } = useAzafata();
  const handleSubmit = (e) => {
    e.preventDefault();
    let subscriberType;
    const formattedDob = getFormattedDOB(
      e.target.dobDay.value,
      e.target.dobMonth.value,
      e.target.dobYear.value
    );
    subscriberType = 'Foco';
    let formData = {
      EMAIL: e.target.EMAIL.value,
      FNAME: e.target.FNAME.value,
      GENDER: e.target.GENDER.value,
      POSTALCODE: e.target.POSTALCODE.value,
      SENSIBLE: getTextValue(questionnaire, 2),
      TIPO: getTextValue(questionnaire, 1),
      SIGNOS: getTextValue(questionnaire, 3),
      MMERGE9: getTextValue(questionnaire, 4),
      MMERGE11: subscriberType,
      MMERGE15: `${e.target.dobMonth.value}/${e.target.dobDay.value}/${e.target.dobYear.value}`,
      MERGE16: "POLO21"
    };
    if (getTextValue(questionnaire, 5)) {
      formData = {
        ...formData,
        MMERGE10: getTextValue(questionnaire, 5),
      };
    }
    if (azafataId) {
      formData = {
        ...formData,
        MERGE14: azafataId,
      };
    }
    subscribe(formData);
    setCustomerName(formData.FNAME);
    setYear(formattedDob);
  };
  if (status === Status.loading) {
    console.log(status, 'loading');
  }
  if (status === Status.error) {
    console.log(error);
  }
  const back = () => {
    if (questionnaire.questions[3].options[1].selected) {
      return setCounter(counter - 2);
    }
    return setCounter(counter - 1);
  };
  return (
    <DefaultForm onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="email">
          <span className="required">Correu electrònic</span> /
          <span className="text-light required">Correo electrónico</span>
        </label>
        <input required type="email" name="EMAIL" />
        <label htmlFor="name">
          <span className="required">Nom</span> /
          <span className="text-light required">Nombre</span>
        </label>
        <input required type="text" name="FNAME" />
        <label htmlFor="gender">
          <span className="required">Gènere</span> /
          <span className="text-light required">Género</span>
        </label>
        <select required name="GENDER" defaultValue="">
          <option value="" disabled>
            Selecciona el gènere / Selecciona el género
          </option>
          <option value="Hombre">Home / Hombre</option>
          <option value="Mujer">Dona / Mujer</option>
          <option value="Otro">Un altre / Otro</option>
        </select>
        <label htmlFor="dobdob-day">
          <span className="required">Data de naixement</span> /
          <span className="text-light required">Fecha de nacimiento</span>
        </label>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
            className="datefield"
            type="number"
            name="dobDay"
            required
            placeholder="Día"
            min="1"
            max="31"
            style={{ marginLeft: 0 }}
          />
          /
          <input
            className="datefield"
            type="number"
            name="dobMonth"
            placeholder="Mes"
            required
            min="1"
            max="12"
          />
          /
          <input
            className="datefield"
            type="number"
            name="dobYear"
            placeholder="Año"
            required
            min="1900"
            max="2020"
            style={{ marginRight: 0 }}
          />
        </div>
        <label htmlFor="city" style={{ marginTop: '20px' }}>
          <span className="required">Codi postal</span> /
          <span className="text-light required">Código postal</span>
        </label>
        <input type="text" name="POSTALCODE" />

        <div className="checkbox-wrapper">
          <input
            type="checkbox"
            id="app-data"
            value="app-data"
            className="checkbox"
            required
          />
          <label htmlFor="app-data">
            Clickant a continuación confirmes haver llegit i acceptat els{' '}
            <strong>
              <a
                download
                href={PDF}
                title="Términos y condiciones Comunidad Mesoestetic.(Español)"
              >
                Termes i condicions
              </a>
            </strong>{' '}
            per formar part de la Comunitat mesoestetic ® /
            <span className="text-light">
              Clicando a continuación confirmas haber leído y aceptar nuestros
              <a
                download
                href={PDF}
                title="Términos y condiciones Comunidad Mesoestetic.(Español)"
              >
                Términos y condiciones
              </a>{' '}
              para formar parte de la Comunidad mesoestetic ®
            </span>
          </label>
        </div>
      </div>

      <div className="actions">
        <span className="secondary" onClick={back}>
          Enrere <span>/ Atrás</span>
        </span>
        <button type="submit" className="primary form">
          <span>Finalitzar test</span> /
          <span className="text-light">Finalizar test</span>
        </button>
      </div>
    </DefaultForm>
  );
};

export default Form;
